.App {
  display: flex;
  justify-content: space-around;
  align-content: center;
  align-items: center;
}

form {
  padding: 3%;
  margin: 2%;
  border: 1px solid black;
}

.card {
  padding: 5px;
  background-color: rgb(225, 199, 199)(225, 199, 199);
  border-radius: 18px;
  height: fit-content;
  width: fit-content;
  box-shadow: inset 8px 8px 16px #7d7d7d,
    inset -8px -8px 16px #ffffff;
}